import React from 'react';
import Helmet from 'react-helmet';
import App from './_app';
import { Mutation } from 'react-apollo';
import PasswordForm from '../forms/password';
import Container from '../components/Container';
import { RESET } from '..//queries/account';
import Alert from '../components/Alert';
import Wrap from '../components/Wrap';

const Login = () => (
    <App>
        <Helmet>
            <title>Reset Your Password</title>
        </Helmet>
        <Wrap muted>
            <Container>
                <h1>Reset FlexTime Password</h1>
                <Mutation mutation={RESET}>
                    {(query, { error, data }) => (<div>
                        <PasswordForm action={query} />
                        {error && <Alert>Incorrect username or password</Alert>}
                        {data && <Alert success>Temporary password is on its way</Alert>}
                    </div>
                    )}
                </Mutation>
            </Container>
        </Wrap>
    </App>
);

export default Login;