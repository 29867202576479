import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Formik, Form, Field } from "formik";
import Submit from "../components/Submit";

const LoginForm = ({ action }) => (
  <Formik
    initialValues={{
      password: "",
    }}
    onSubmit={(variables) => action({ variables })}
    render={() => (
      <Form>
        <label>
          Email *
          <Field name="email" type="email" required />
        </label>

        <Submit text="Reset" />
        <Link style={{ marginLeft: "1rem" }} to="/login">
          Login
        </Link>
      </Form>
    )}
  />
);

LoginForm.propTypes = {
  action: PropTypes.func,
};

export default LoginForm;
